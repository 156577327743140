import { copyTextToClipboard } from "~/shared/utils";
import {
  LaunchReport,
  LaunchReportResponse,
  LaunchReportView,
  LaunchReportViewResponse,
} from "~/types/LaunchReport";
import { ReportSnapshot, ReportSnapshotResponse } from "~/types/ReportSnapshot";
import { GroupBy, Provider } from "~/types/shared";

export const useLaunchReport = () => {
  const getMappedLaunchReportSnapshpts = (
    snapshots: Array<ReportSnapshotResponse<LaunchReportResponse>>,
  ): Array<ReportSnapshot<LaunchReport>> => {
    return snapshots.map((snapshot) => {
      return {
        id: snapshot.id,
        uuid: snapshot.uuid,
        type: snapshot.type,
        createdAt: new Date(snapshot.createdAt),
        data: getMappedLaunchReports([snapshot.data])[0],
        creator: snapshot.creator,
        reportId: snapshot.reportId,
      };
    });
  };

  const getMappedLaunchReports = (
    reports: Array<LaunchReportResponse>,
  ): Array<LaunchReport> => {
    const mappedReports = reports.map<LaunchReport>((report) => {
      return {
        ...report,
        performanceStartDate:
          report.performanceStartDate != null
            ? new Date(report.performanceStartDate)
            : null,
        performanceEndDate:
          report.performanceEndDate != null
            ? new Date(report.performanceEndDate)
            : null,
        launchStartDate:
          report.launchStartDate != null
            ? new Date(report.launchStartDate)
            : null,
        launchEndDate:
          report.launchEndDate != null ? new Date(report.launchEndDate) : null,
        views: getMappedLaunchReportViews(report.views, report.groupBy),
      };
    });

    mappedReports.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return mappedReports;
  };

  const getMappedLaunchReportViews = (
    views: Array<LaunchReportViewResponse>,
    groupBy: GroupBy,
  ): Array<LaunchReportView> => {
    const { getMappedCreativeReportingGroups } = useCreativeReporting();
    return views.map<LaunchReportView>((viewRes) => {
      return {
        info: viewRes.info,
        launched: {
          groups: getMappedCreativeReportingGroups(
            viewRes.launched.groups,
            groupBy,
          ),
          pageNumber: viewRes.launched.pageNumber,
          total: viewRes.launched.total,
          accumulated: viewRes.launched.accumulated,
          groupIds: viewRes.launched.groupIds,
        },
        winner: {
          groups: getMappedCreativeReportingGroups(
            viewRes.winner.groups,
            groupBy,
          ),
          pageNumber: viewRes.winner.pageNumber,
          total: viewRes.winner.total,
          accumulated: viewRes.winner.accumulated,
          groupIds: viewRes.winner.groupIds,
        },
        looser: {
          groups: getMappedCreativeReportingGroups(
            viewRes.looser.groups,
            groupBy,
          ),
          pageNumber: viewRes.looser.pageNumber,
          total: viewRes.looser.total,
          accumulated: viewRes.looser.accumulated,
          groupIds: viewRes.looser.groupIds,
        },
        opportunities: {
          groups: getMappedCreativeReportingGroups(
            viewRes.opportunities.groups,
            groupBy,
          ),
          pageNumber: viewRes.opportunities.pageNumber,
          total: viewRes.opportunities.total,
          accumulated: viewRes.opportunities.accumulated,
          groupIds: viewRes.opportunities.groupIds,
        },
      };
    });
  };

  const getActiveView = (
    provider: Provider,
    report: LaunchReport,
  ): LaunchReportView | null => {
    if (report.views.length <= 0) {
      return null;
    }
    return (
      report.views.find((view) => view.info.provider === provider) ??
      report.views[0]
    );
  };

  const getReportDetailPage = (reportUuid: string) => {
    return "/launch/detail/" + reportUuid;
  };

  const getReportDuplicatePage = (reportId: number) => {
    return "/launch?reportId=" + reportId + "&mode=duplicate";
  };

  const copyShareLink = (
    reportUuid: string,
    provider: Provider = Provider.META,
  ) => {
    const config = useRuntimeConfig();
    const { notify } = useNotifications();
    const { track, AnalyticsEvents } = useSegment();
    let shareLink = config.public.baseUrl + "/launch/share/" + reportUuid;
    if (provider != null) {
      shareLink += "?provider=" + provider;
    }
    copyTextToClipboard(shareLink);
    notify(null, "Link copied");
    track(AnalyticsEvents.SHARE_LAUNCH_REPORT);
  };

  const createReport = async (input: {
    clientId: number;
    folderId: number | null;
  }) => {
    const notificationStore = useNotificationStore();
    const store = useLaunchReportStore();

    const reportIdOrError = await store.createReport({
      title: null,
      clientId: input.clientId,
      description: null,
      meta: true,
      tiktok: true,
      youtube: true,
      folderId: input.folderId,
    });

    if (reportIdOrError == null) {
      notificationStore.notify({
        type: "error",
        message: "Something went wrong",
      });
      return;
    }

    if (typeof reportIdOrError === "string") {
      notificationStore.notify({
        type: "error",
        message: reportIdOrError,
      });
      return;
    }

    const { uuid } = reportIdOrError;

    const router = useRouter();
    router.push(getReportDetailPage(uuid));
  };

  const duplicateReport = async (input: {
    templateReportId: number;
    clientId: number;
  }) => {
    const notificationStore = useNotificationStore();
    const store = useLaunchReportStore();

    const reportIdOrError = await store.duplicateReport({
      reportId: input.templateReportId,
      clientId: input.clientId,
      folderId: null,
    });

    if (reportIdOrError == null) {
      notificationStore.notify({
        type: "error",
        message: "Something went wrong",
      });
      return;
    }

    if (typeof reportIdOrError === "string") {
      notificationStore.notify({
        type: "error",
        message: reportIdOrError,
      });
      return;
    }

    const { uuid } = reportIdOrError;

    const router = useRouter();
    router.push(getReportDetailPage(uuid));
  };

  const deleteReport = async (reportId: number) => {
    const router = useRouter();
    const { notify } = useNotifications();
    const store = useLaunchReportStore();

    const confirmed = confirm(
      "Are you sure you want to delete this report? This action cannot be undone.",
    );

    if (confirmed) {
      const errorMaybe = await store.deleteReport(reportId);
      notify(errorMaybe, "Report deleted successfully.");
      await router.push("/");
    }
  };

  const createSnapshot = async (
    reportId: number,
    provider: Provider = Provider.META,
  ) => {
    const config = useRuntimeConfig();
    const { track, AnalyticsEvents } = useSegment();
    const store = useLaunchReportStore();
    const { notify } = useNotifications();
    const errorOrSnapshot = await store.createSnapshot({ reportId });
    if (typeof errorOrSnapshot === "string") {
      notify(errorOrSnapshot, "Snapshot created successfully.");
    } else if (errorOrSnapshot == null) {
      notify("Something went wrong", "");
    } else {
      notify(null, "Snapshot created successfully.");
      track(AnalyticsEvents.SNAPSHOT_LAUNCH_REPORT);
      let url =
        config.public.baseUrl + "/launch/snapshot/" + errorOrSnapshot.uuid;
      if (provider != null) {
        url += "?provider=" + provider;
      }
      await navigateTo(url, { external: true, open: { target: "_blank" } });
    }
  };

  return {
    getMappedLaunchReportSnapshpts,
    getMappedLaunchReports,
    getMappedLaunchReportViews,
    getActiveView,
    getReportDetailPage,
    getReportDuplicatePage,
    copyShareLink,
    createReport,
    deleteReport,
    createSnapshot,
    duplicateReport,
  };
};

import { arraysEqual } from "~/shared/utils";
import { CreativeInfo } from "~/types/Creative";
import { CreativeComparisonGroup } from "~/types/CreativeComparison";
import { CreativeReportingGroup } from "~/types/CreativeReporting";
import { GroupBy } from "~/types/shared";
import { TestingLogColumnGroup } from "~/types/TestingLog";

export const useAssets = () => {
  const resolveGroupAssets = async <
    T extends CreativeReportingGroup | CreativeComparisonGroup,
  >(dto: {
    groups: T[];
    slice: number | undefined;
    groupBy: GroupBy;
  }) => {
    if (dto.groups.length <= 0) {
      return [];
    }
    const ads = dto.groups
      .flatMap((g) => g.creatives.slice(0, dto.slice))
      .map((c) => c.info);
    const adsWithAssets = await resolveAdGroupAdAssets(ads);
    const { getMappedCreatives } = useCreatives();
    return dto.groups
      .map((g) => {
        return {
          ...g,
          creatives: g.creatives.map((c) => {
            const info = c.info;
            const ad = adsWithAssets.find((a) => a.id === info.id);
            // Since ad_group_ad_creatives may belong to multiple ads, we can't match by id as this is not unique
            const _images = adsWithAssets.find((a) =>
              arraysEqual(a.imageIds, info.imageIds),
            )?.images;
            const _videos = adsWithAssets.find((a) =>
              arraysEqual(a.videoIds, info.videoIds),
            )?.videos;
            const images =
              dto.groupBy === GroupBy.DYNAMIC_CREATIVE ? _images : ad?.images;
            const videos =
              dto.groupBy === GroupBy.DYNAMIC_CREATIVE ? _videos : ad?.videos;
            return {
              ...c,
              info: {
                ...info,
                images: images ?? info.images,
                videos: videos ?? info.videos,
              },
            };
          }),
        };
      })
      .map((g) => {
        return {
          ...g,
          creatives: getMappedCreatives(g.creatives),
        };
      });
  };

  async function resolveTestingLogGroupAssets(
    groups: Array<TestingLogColumnGroup>,
  ) {
    const ads = groups.flatMap((g) => g.creatives).map((c) => c.info);
    const adsWithAssets = await resolveAdGroupAdAssets(ads);
    const { getMappedCreatives } = useCreatives();
    return groups
      .map((g) => {
        return {
          ...g,
          creatives: g.creatives.map((c) => {
            const info = c.info;
            const images = adsWithAssets.find((a) => a.id === info.id)?.images;
            const videos = adsWithAssets.find((a) => a.id === info.id)?.videos;
            return {
              ...c,
              info: {
                ...info,
                images: images ?? info.images,
                videos: videos ?? info.videos,
              },
            };
          }),
        };
      })
      .map((g) => {
        return {
          ...g,
          creatives: getMappedCreatives(g.creatives),
        };
      });
  }

  const resolveAdGroupAdAssets = async <T extends CreativeInfo>(
    ads: T[],
  ): Promise<T[]> => {
    if (ads.length <= 0) {
      return [];
    }
    const creativeReportingStore = useCreativeReportingStore();
    const unresolvedAds = ads.filter(
      (ad) =>
        !creativeReportingStore.assets.find(
          (a) => a.providerId === ad.providerId,
        ),
    );
    const providerIds = unresolvedAds.map((ad) => ad.providerId);
    await creativeReportingStore.getAssetsOfAds(providerIds);
    return ads.map((ad) => {
      const assets = creativeReportingStore.assets.find(
        (a) => a.providerId === ad.providerId,
      );
      return {
        ...ad,
        images: assets?.images ?? ad.images,
        videos: assets?.videos ?? ad.videos,
      };
    });
  };

  return {
    resolveGroupAssets,
    resolveAdGroupAdAssets,
    resolveTestingLogGroupAssets,
  };
};
